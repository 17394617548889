<template lang="pug">
  div
    .row.justify-content-center
      .col-11.header
        div(style="margin: 0px auto;width: 50%;")
          img(class="client_logo" :src="Logo" v-openlog)


    .main_box
      h3(class="text-center") Version {{ version }}
      b-form(class="form-horizontal" @submit.stop.prevent="saveUserData")
        //- b-form-group( id="fieldset-horizontal" label-cols-sm="4" label-cols-lg="3" label-for="input-horizontal")
        b-form-input(class="form-control" type="text" v-model="name" placeholder="Nombre" )
        //- b-form-group( id="fieldset-horizontal" label-cols-sm="4" label-cols-lg="3" label-for="input-horizontal")
        b-form-input(class="form-control" type="text" v-model="last_name" placeholder="Apellido" )
        //- b-form-group( id="fieldset-horizontal" label-cols-sm="4" label-cols-lg="3" label-for="input-horizontal")
        b-form-input(class="form-control" type="text" v-model="user_id" placeholder="Cédula")
        //- b-form-group( id="fieldset-horizontal" label-cols-sm="4" label-cols-lg="3" label-for="input-horizontal")
        b-form-input(class="form-control" type="text" v-model="phone_number" placeholder="Celular")
        div(style="text-align: center;")
          b-button(
            type="submit"
            variant="outline-bluecolmedica"
            class="my-2 btn-block align-self-center mx-auto"
            v-for="q in queues" :key="q.id"
            @click="queue_dt = q"
          ) {{ q.name }} - {{ q.branch.label }}
        h5.text-center(v-if="errorMessage") Se ha presentado un error al crear el usuario con la información existente.
      button.btn.btn-info(@click="cleanCache" class="btn-block") Limpiar todo el cache.
</template>

<script>
// import api_requests from "@/store/actions";
import { mapActions, mapState } from "vuex";
import _get from "lodash/get";
import QS from "query-string";
import { utf8_to_b64 } from "@/utils/decoder";
import kuid from "kuid";
import LS from "../helpers/localStorage";
import { getOneUseToken } from "@/helpers/Meet";
import LogoColmedica from "@/assets/colmedica_logo.png";

export default {
  name: "Profile",
  data() {
    return {
      windowOpened: null,
      room_id: kuid(),
      local_queue: {},
      queryString: {},
      name: LS.getItem("name_user") || "",
      last_name: LS.getItem("last_name_user") || "",
      user_id: LS.getItem("user_id") || "",
      phone_number: LS.getItem("phone_number") || "",
      e_mail: LS.getItem("e_mail") || "",
      queue_dt: null,
      queue_develop: { id: 2911, branch: { id: 119 } },
      errorMessage: false,
      LogoColmedica
    };
  },
  watch: {
    name(val) {
      LS.setItem("name_user", val);
    },
    last_name(val) {
      LS.setItem("last_name_user", val);
    },
    user_id(val) {
      LS.setItem("user_id", val);
    },
    phone_number(val) {
      LS.setItem("phone_number", val);
    },
    e_mail(val) {
      LS.setItem("e_mail", val);
    }
  },
  created() {
    LS.removeItem("naturaleza");
    LS.setItem("user_type", "client");
    this.queryParser();
    this.startupLocal();
  },
  methods: {
    ...mapActions(["startup", "setTramite", "getCompany"]),

    async startupLocal() {
      this.startup({ forced: true });
      await this.getCompany(this.env.VUE_APP_COMPANY || "brilliant");
    },

    queryParser() {
      this.queryString = QS.parse(location.href.split("?")[1]);
    },
    async saveUserData() {
      try {
        if (this.windowOpened && typeof this.windowOpened.close === "function")
          this.windowOpened.close();
        const json = JSON.stringify({ ...this.qs, to: await getOneUseToken() });
        let converted = utf8_to_b64(json);
        console.log("JSON Sended", json);
        console.log("B64 Sended", converted);
        this.windowOpened = window.open(
          `${location.origin}/#/?pr=${converted}`,
          "_blank"
        );
      } catch (error) {
        console.error("Error in one unse token", error);
      }
    },
    redirectToNext() {
      this.$router.push({ name: "BeforeTurn" });
    },
    cleanCache() {
      localStorage.clear();
      this.name = "";
      this.last_name = "";
      this.user_id = "";
      this.phone_number = "";
      this.e_mail = "";
    }
  },
  computed: {
    ...mapState({
      environment: state => state.environment,
      version: state => state.version,
      sessionMaster: state => state.sessionMaster,
      tramite_info: state => state.tramite_info,
      company: state => state.company,
      this_user_data: state => state.client_data,
      turn: state => state.turn,
      c_data: state => state.client_data,
      env: state => state.env
    }),

    demoMode() {
      return this.env.VUE_APP_DEMO_MODE === "true";
    },

    LogoDemo() {
      return this.env.VUE_APP_DEMO_LOGO;
    },

    Logo() {
      return this.demoMode && this.LogoDemo
        ? this.LogoDemo
        : this.env.VUE_APP_LOGO || this.LogoColmedica;
    },

    companyName() {
      return _get(this.company, "name");
    },
    queues() {
      let newlist = [];
      if (this.company) {
        for (const key in this.company) {
          // eslint-disable-next-line no-prototype-builtins
          if (this.company.hasOwnProperty(key) && key.charAt(0) !== "_") {
            const element = this.company[key];
            for (const iterator of element.turnos) {
              let toSave = {
                id: _get(iterator, "tramite_id") || "none",
                name: _get(iterator, "tramite_name") || "none",
                branch: {
                  id: _get(element, "branch_id") || "none",
                  label: _get(element, "branch_label") || "none"
                }
              };
              newlist.push(toSave);
            }
          }
        }
      }
      return newlist;
    },
    qs() {
      return {
        ti: "CC",
        nu: this.user_id,
        ma: "normal",
        na: this.name,
        ap: this.last_name,
        tr: _get(this.queue_dt, "id"),
        so: "123456",
        fu: "web",
        // em: "Correo electrónico",
        ce: this.phone_number,
        to: "none",
        su: _get(this.queue_dt, "branch.id")
      };
    }
  }
};
</script>
<style scoped>
.main_box {
  width: 75vw;
  margin: auto;
  font-size: 1.4em;
}
.header {
  text-align: center;
}
.form-horizontal .form-control {
  color: #222;
  background-color: transparent;
  font-size: 1em;
  font-weight: 400;
  letter-spacing: 1px;
  width: calc(100% - 40px);
  height: 33px;
  padding: 3px 10px 0 0;
  box-shadow: none;
  border: none;
  border-radius: 0;
  display: inline-block;
  transition: all 0.3s;
}
.form-horizontal textarea.form-control {
  height: auto;
  width: 100%;
}
.form-horizontal .form-control::placeholder {
  color: #b5b5b5;
  font-size: 15px;
  font-weight: 400;
  text-transform: capitalize;
}
.form-horizontal .form-control {
  background-color: rgba(255, 255, 255, 0.15);
  margin: 0 0 15px;
  border-bottom: 2px solid #e7e7e7;
}
.client_logo {
  width: 120px;
  height: auto;
}
.header {
  margin-top: 5vh;
  margin-bottom: 5vh;
}
.submit_button {
  text-align: center;
}
</style>
